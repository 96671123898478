<template>
  <div 
    :class="{ 'pa-4': breakpoint('xs')}"
    class="club pb-8"
  >
    <div
      class="toolbar white rounded-t pa-4 d-flex align-center scrollable x"
      :style="{ top: breakpoint('md') ? '64px' : '56px' }"
    >
      <v-btn-toggle
        :value="filters.status"
        active-class="active primary primary--text"
        mandatory
        class="status-filter scrollable-x flex-shrink-1 mr-4"
      >
        <v-btn
          v-for="option in controller.filters.status.options"
          :key="`status-filter-option-${option.value}`"
          :value="option.value"
          height="40"
          outlined
          :class="{ 'text--disabled': option.count==0 }"
          class="option button px-4 text-overline"
          @click="setFilter('status', option.value)"
        >
          {{ option.text }}
          <!-- <v-badge
            v-if="option.count"
            inline
            :color="filters.status.value==option.value ? 'primary' : 'grey lighten-1'"
            :content="option.count"
            class="small"
          /> -->
        </v-btn>
      </v-btn-toggle>

      <v-btn
        text
        color="primary"
        :loading="controller.update.loading"
        :disabled="controller.update.disabled||controller.update.loading"
        @click="select"
      >
        <v-icon left>
          {{ icons.add }}
        </v-icon>
        Adicionar
      </v-btn>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            color="grey"
            href="https://docs.google.com/spreadsheets/d/1oBIBTg1tNDUbZ9OoMCo37Or7yLall1KVt-g5JOR7azA"
            target="_blank"
            class="mx-2"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>
              {{ icons.template }}
            </v-icon>
          </v-btn>
        </template>
        Template
      </v-tooltip>
      <!-- <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            :disabled="controller.list.disabled||controller.list.loading"
            class="mx-2"
            v-bind="attrs"
            v-on="on"
            @click="get"
          >
            <v-icon>
              {{ icons.refresh }}
            </v-icon>
          </v-btn>
        </template>
        Atualizar
      </v-tooltip> -->
      <v-spacer />
      <v-text-field
        v-model="controller.search.query"
        :loading="controller.search.loading"
        :disabled="controller.search.disabled"
        :prepend-icon="icons.search"
        solo
        flat
        dense
        placeholder="Buscar benefício"
        clearable
        hide-details
        class="search-field field"
      />
    </div>
    <v-simple-table
      fixed-header
      class="list"
    >
      <template v-slot:default>
        <thead>
          <tr>
            <th 
              v-for="(column, key) in header"
              :key="'header-'+key"
              :width="'width' in column ? column.width : 'auto'"
              :class="['text-'+('align' in column ? column.align : 'left')]"
            >
              {{ column.title }}
            </th>
          </tr>
        </thead>
        <v-progress-linear
          :active="controller.list.loading"
          height="2"
          indeterminate
          absolute
        />
        <tbody>
          <v-menu 
            v-for="item in list"
            :key="'benefit-'+item.id_beneficio"
            :close-on-content-click="false"
            offset-y
            offset-x
            right
            max-width="360"
          >
            <template v-slot:activator="{ on, attrs }">
              <tr
                v-bind="attrs"
                v-on="on"
                class="benefit-row"
              >
                <td 
                  v-for="(column, key) in header"
                  :key="'benefit-'+item.id_beneficio+'-'+key"
                  :class="[{ truncate: 'truncate' in column && column.truncate }, 'text-'+column.align, item.color+'--text text--darken-1']"
                >
                  <v-progress-circular
                    v-if="key=='status'&&controller.list.items[item.id_beneficio].loading"
                    indeterminate
                    color="primary"
                    size="20"
                    width="2"
                  />
                  <v-hover 
                    v-else-if="key=='status'"
                    v-slot="{ hover }"
                  >
                    <v-checkbox
                      v-model="item[key]"
                      :indeterminate="item[key]==2"
                      :true-value="1"
                      :false-value="0"
                      :indeterminate-icon="icons.status_2"
                      :on-icon="icons.status_1"
                      :off-icon="icons.status_0"
                      :title="!item[key] ? 'Inativo' : item[key]==2 ? 'A Aprovar' : 'Ativo'"
                      hide-details
                      readonly
                      class="mt-0 mb-1"
                      @click.prevent.stop
                    >
                      <template v-slot:label>
                        <v-card
                          v-show="hover"
                          :class="{ 'd-flex': hover }"
                          class="status-control"
                        >
                          <v-btn
                            v-for="s in [1,2,0]"
                            :key="'benefit-'+item.id_beneficio+'-status-'+s"
                            icon
                            tile
                            :disabled="item[key]==s"
                            :color="s===1 ? 'success' : s===2 ? 'warning' : 'grey'"
                            @click.stop="setItem(item, 'status', s)"
                          >
                            <v-icon>{{ icons['status_'+s] }}</v-icon>
                          </v-btn>
                        </v-card>
                      </template>
                    </v-checkbox>
                  </v-hover>
                  <span 
                    v-else
                    :title="item[key]"
                    :class="{ 'text-decoration-line-through': key=='text' && item.used_percent==1 }"
                  >
                    {{ item[key] }}
                  </span>
                </td>
              </tr>
            </template>
            <v-list>
              <!-- <v-subheader
                class="font-weight-medium"
              >
                {{ item.text }}
              </v-subheader>
              <v-divider class="my-2" /> -->
              <v-list-item
                class="text-overline"
              >
                {{ item.usuarios.length==0 ? 'Não utilizado' : 'Usuários:' }}
              </v-list-item>
              <v-list-item
                v-for="benefit_user in item.usuarios"
                :key="'benefit-'+item.id_beneficio+'-user-'+benefit_user.usuario"
                class="text-overline"
                @click="openDriver(benefit_user.usuario)"
              >
                {{ benefit_user.usuario }}
              </v-list-item>
            </v-list>
          </v-menu>
        </tbody>
      </template>
    </v-simple-table>

    <input
      type="file"
      :value="controller.update.files"
      ref="update-field"
      class="d-none"
      @change="file($event, false)"
    >
  </div>
</template>

<style lang="scss">

  .club .toolbar {
    position: sticky !important;
    z-index: 3;
  }

  .list {
    position: relative;
  }
  .truncate {
    max-width: 1px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .search-field {
    max-width: 240px;
    background: transparent;
  }
  .toolbar .search-field .v-input__slot {
    background: transparent;
  }

  .club .toolbar .status-filter .button.option.active {
    border-bottom-color: var(--primary) !important;
    font-weight: 900;
    &:before {
      opacity: .04;
    }
  }
  .club .toolbar .status-filter .option {
    border-bottom: 2px solid !important;
    background: transparent !important;
  }
  .club .benefit-row {
    position: relative;
  }
  .club .status-control {
    position: absolute;
    top: -14px;
    left: -38px;
    min-width: 108px;
  }
</style>

<script>
import services from '@/services'
import { mdiMagnify, mdiPlus, mdiRefresh, mdiFileTable, mdiCheckboxMarked, mdiCheckboxIntermediate, mdiCloseBox } from '@mdi/js';
import { _ } from 'numeral';
  import {
    sync,
  } from 'vuex-pathify'

  export default {
    name: 'Club',
    metaInfo: {
      title: 'Clube'
    },

    data: () => ({
      icons: {
        search: mdiMagnify,
        add: mdiPlus,
        refresh: mdiRefresh, 
        template: mdiFileTable,
        status_0: mdiCloseBox,
        status_1: mdiCheckboxMarked, 
        status_2: mdiCheckboxIntermediate, 
      },
      controller: {
        search: {
          query: null,
          loading: false,
          disabled: false,
        },
        filters: {
          status: {
            options: [
              {
                text: 'Ativos',
                value: 1,
              },
              {
                text: 'A Aprovar',
                value: 2,
              },
              {
                text: 'Inativos',
                value: 0,
                order: [['dt_fim', 'text'], ['desc', 'asc']]
              }
            ]
          },
        },
        update: {
          files: null,
          allowed: ['text/tab-separated-values', '.tsv'],
          max: 100000,
          errors: [],
          success: null,
          loading: false,
          disabled: false,
        },
        list: {
          items: {},
          header: {
            'status': {
              title: 'Status',
              width: '5%',
              mobile: true,
            },
            'id_beneficio': {
              title: 'Id',
              width: '5%',
            },
            'text': {
              title: 'Benefício',
              truncate: true,
              width: '40%',
              mobile: true,
            },
            'chave_mel': {
              title: 'Chatbot',
              width: '5%',
            },
            'valid': {
              title: 'Validade',
              truncate: true,
              mobile: true,
            },
            'qtde_usuario': {
              title: 'Qtd Usuário',
              width: '10%',
              align: 'right'
            },
            'used_total': {
              title: 'Usado/Total',
              width: '10%',
              align: 'right'
            },
          },
          loading: false,
          disabled: false
        },
      },
    }),

    computed: {
      filters: sync('club/state@filters'),
      user: sync('user/data'),
      toast: sync('app/toast'),

      header () {
        const isMobile = this.breakpoint(null, 'xs');
        return _.pickBy(this.controller.list.header, column => {
          return !isMobile||(_.has(column, 'mobile')&&column.mobile);
        })
      },

      list () {
        const items = _.cloneDeep(this.controller.list.items);
        let query = this.controller.search.query;
        query = this.isValid(query) ? query.normalize("NFD").replace(/\p{Diacritic}/gu, "") : '';
        let list = this.searchEngine(items, query, true, ['text']);
        list = !_.isEmpty(list) ? list : this.searchEngine(items, query, false, ['text']);
        const filters = _.clone(this.filters);
        const status = _.find(this.controller.filters.status.options, ['value', filters.status]);
        const order = _.has(status, 'order') ? status.order : [['dt_fim', 'text'], ['asc', 'asc']];
        return _.orderBy(_.size(filters)>0 ? _.filter(list, filters) : list, ...order);
      },
    },

    watch: {
      filters: {
        deep: true,
        handler (filters) {
          this.get();
        }
      }
    },

    methods: {
      ...services,

      setFilter (key, value) {
        const filters = { ...this.filters, [key]: value };
        this.$set(this, 'filters', filters);
      },
      
      setItem (item, key, value) {
        this.$set(item, key, value);
        this.update(item, this.controller.list.items[item.id_beneficio]);
      },

      select () {
        this.$refs["update-field"].click();
      },
      file (event, dropped) {
        const files = dropped
          ? event.dataTransfer.files
          : event.target.files;
        console.log("file", files);

        const $ = this;
        const allowed = _.filter(files, file => {
          // const format = _.last(_.split(file.name, '.'));
          const allowed = _.some([file.type, file.name], property => _.some($.controller.update.allowed, type => property.indexOf(type)>=0));
          console.log('allowed', $.controller.update.allowed, 'file', file.type, allowed);
          return allowed;
        })
        if (_.size(allowed)>0) {
          this.parse(_.first(allowed), (json) => {
            this.update(json);
          })
        }
        const alert = _.size(files)-_.size(allowed);
        if (alert>0) {
          const message = alert==1 ? 'Formato de arquivo não permitido.' : alert + 'arquivos em formato não permitido.'
          this.toggleToast(true, message);
        }
      },

      setList (data) {
        const date_format = { iso: 'YYYY-MM-DD HH:mm:ss', full: 'DD/MMM HH:mm', short: 'DD/MMM' };
        const benefits = _.orderBy(_.map(data, benefit => {
          benefit.dt_inicio = this.$moment.utc(benefit.dt_inicio).local().format(date_format.iso);
          const start = this.$moment(benefit.dt_inicio);
          benefit.dt_fim = this.$moment.utc(benefit.dt_fim).local().format(date_format.iso);
          const end = this.$moment(benefit.dt_fim);
          const valid = _.capitalize(`${start.format(start.hour()==0 ? date_format.short : date_format.full)} – ${end.hour()==0 ? end.subtract(1, 'd').format(date_format.short) : end.format(date_format.full)}`)
          benefit.used_total = `${_.size(benefit.usuarios)}/${benefit.qtde}`;
          benefit.used_percent = _.size(benefit.usuarios)/benefit.qtde;
          benefit.color = benefit.used_percent==1 ? 'error' : benefit.used_percent>=.5 ? 'orange' : undefined;
          benefit.qtde_usuario = benefit.qtde_usuario||'–';
          benefit.available = this.$moment().isBefore(end);
          benefit.loading = false;

          switch (benefit.chave_mel) {
            case 'theatres':
              const { theatre, show, date } = benefit.beneficio;
              const text = `${show} | ${theatre} – ${this.$moment.utc(date).local().format('DD/MM HH:mm')}`
              benefit = { ...benefit, text, valid }
              break;
              
            default:
              benefit.text = benefit.beneficio;
              benefit.valid = valid;
              break;
          }
          return benefit;
        }), ['dt_inicio'], ['asc']);

        _.each(_.keyBy(benefits, 'id_beneficio'), (benefit, id) => {
          this.$set(this.controller.list.items, id, benefit);
        });
      },

      get () {
        this.controller.list.loading = true;
        
        const username = this.user.username;
        const token = this.user.auth.token;
        const filters = this.filters;
        const data = {
          username,
          authTk: token,
          ...filters
        };

        this.$api.COM
          .get('/driver/club', { params: data })
          .then(response => {
            console.log('get => ',response);
            if (response.data.retorno==200) {
              this.setList(response.data.benefits);

            }else if (response.data.retorno==401) {
              this.getout();
              this.toggleToast(
                true,
                this.$t('errors.session_expired'),
                5000,
                false
              );
            }else{
              this.handleError(error);
            }
          })
          .catch(error => {
            // this.$emit('toggle-alert', this.$t('messages.waiting_response'));
            // try again in 7s
            // this.view.loader = setTimeout(($) => {
            //   this.view.loader = null;
            //   $.getBenefits(script);
            // }, 7000, this, script);
            this.handleError(error);
          })
          .finally(() => {
            this.controller.list.loading = false;
          });
      },

      update (items, loader=this.controller.update) {
        loader.loading = true;
        
        const username = this.user.username;
        const token = this.user.auth.token;
        const benefits = this.format(items);
        const data = {
          username,
          authTk: token,
          benefits
        };

        console.log('update...', data);

        this.$api.COM
          .post('/driver/club', data)
          .then(response => {
            console.log('update => ',response);
            if(response.data.retorno==200){
              this.setList(response.data.benefits);

              this.toggleToast(
                true,
                this.$tc('club.save_success', _.size(items)),
                5000,
                false
              );

            }else if(response.data.retorno==401) {
              this.getout();
              this.toggleToast(
                true,
                this.$t('errors.session_expired'),
                5000,
                false
              );
            }else{
              this.toggleToast(
                true,
                this.$tc('club.save_failed', _.size(items)),
                5000,
                false
              );
              this.handleError(error);
            }
          })
          .catch(error => {
            // this.$emit('toggle-alert', this.$t('messages.waiting_response'));
            // try again in 7s
            // this.view.loader = setTimeout(($) => {
            //   this.view.loader = null;
            //   $.getBenefits(script);
            // }, 7000, this, script);
            this.toggleToast(
              true,
              this.$tc('club.save_failed', _.size(items)),
              5000,
              false
            );
            this.handleError(error);
          })
          .finally(() => {
            loader.loading = false;
          });
      },
      parse (file, callback) {
        const reader = new FileReader();
        reader.onload = () => {
          const csv = reader.result;
          const lines = csv.split('\n');
          const header = lines[0].split('\t').map(value => value.trim());
          const json = lines.slice(1).map(line => {
            const fields = line.split('\t').map(value => value.trim().replaceAll(/\"/g, ''));
            return Object.fromEntries(header.map((h, i) => [h, fields[i]]));
          })
          callback(json);
          this.controller.update.files = null;
        }
        // start reading the file. When it is done, calls the onload event defined above.
        reader.readAsText(file)
      },
      format (data) {
        data = !_.isArray(data) ? [data] : data;
        const date_format = 'YYYY-MM-DD HH:mm:ss';
        data = _.map(data, benefit => {
          let { id_beneficio=null, status=null, chave_mel, qtde, qtde_usuario, beneficio, beneficiario, dt_inicio, dt_fim } = benefit;
          id_beneficio = id_beneficio===null&&id_beneficio==='' ? null : parseInt(id_beneficio);
          status = status===null||status==='' ? 2 : parseInt(status);
          if (!_.isEmpty(dt_inicio)) dt_inicio = this.$moment(dt_inicio).utc().format(date_format);
          if (!_.isEmpty(dt_fim)) dt_fim = this.$moment(dt_fim).utc().format(date_format);
          qtde = parseInt(qtde);
          qtde_usuario = parseInt(qtde_usuario);
          beneficiario = parseInt(beneficiario);

          switch (benefit.chave_mel) {
            case 'theatres':
              const info = ['theatre', 'show', 'date', 'sendTo', 'url'];
              if (_.isEmpty(dt_inicio)) {
                dt_inicio = this.$moment(benefit.date).subtract(5,'weeks').utc().format(date_format);
              }
              if (_.isEmpty(dt_fim)) {
                dt_fim = this.$moment(benefit.date).subtract(15,'days').utc().format(date_format);
                // benefit.dt_fim = benefit.dt_fim.isoWeekday()<=5 ? 
                //   benefit.dt_fim.subtract(24,'hours').utc().format(date_format) : 
                //   benefit.dt_fim.subtract(benefit.dt_fim.isoWeekday()==7 ? 2 : 1, 'days').hour(17).subtract(15, 'minutes').utc().format(date_format);
              }
              if (_.isEmpty(beneficio)) {
                benefit.date = this.$moment(benefit.date).utc().format(date_format);
                beneficio = _.pick(benefit, info);
              }
              break;
          
            default:
              break;
          }
          return { id_beneficio, status, chave_mel, qtde, qtde_usuario, beneficio, beneficiario, dt_inicio, dt_fim };
        });
        return data;
      },

      openDriver (cpf) {
        this.$router.push({ path: `/drivers?search=${cpf}` });
      }
    },

    mounted () {
      this.get();
    }
  }
</script>